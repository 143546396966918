<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col xs="12" lg="12">
        <b-card>
          <h4 class="d-inline-block"><i class="nav-icon icon-layers"></i> Invoices </h4><!-- <router-link class="btn btn-success float-right" :to="{ name: 'Vendor Create Invoice'}"><i class="fa fa-plus"></i> Create Invoice</router-link> -->
          <hr>
          <div class="row my-3 mb-1">
            <div class="col-12 col-md-3 mb-3">
                <!-- {{this.listOfVendors}} -->
              <h5>Select Vendor</h5>
              <v-select
                class="project-select"
                v-model="vendorSelection"
                label="company_name"
                :options="listOfVendors"
                placeholder="Search For Vendor"
                @change="getProjects(), $session.set('vendorSelection', vendorSelection), projectid = []"
              ></v-select>
            </div>
            <div class="col-12 col-md-3 mb-3" v-if="vendorSelection">
              <h5>Select Project</h5>
              <v-select
                class="project-select"
                v-model="projectid"
                label="projectname"
                :options="projectData"
                placeholder="Search For Project"
                @change="loadInvoices()"
              ></v-select>
            </div>
            <div class="col-9 col-md-3 mb-3" v-if="vendorSelection != null && projectid">
              <h5>Select Status</h5>
              <v-select
                class="project-select"
                v-model="type"
                label="type"
                :options="typeOptions"
                @input="loadInvoices()"
              ></v-select>
            </div>
            <div v-if="projectid" class="col-2 col-md-3">
              <h5>&nbsp;</h5>
              <button @click="loadInvoices()" class="btn btn-primary"><i class="fa fa-sync-alt"></i></button>
            </div>
          </div>
          <v-client-table class="vendor-table inv-status-table table-responsive" :columns="columns" :data="invoiceData" :options="options" :theme="theme" id="dataTable">
            <span slot="total" slot-scope="props">
            {{formatPrice(props.row.total)}}   
            </span>

            <div slot="quickpay_total" slot-scope="props">
              <span v-if="props.row.quickpay_total > 0 && $moment(props.row.endtday) > $moment()">
               {{formatPrice(props.row.quickpay_total)}}
              </span>
            </div>
            <span slot="creation_date" slot-scope="props">{{props.row.creation_date | moment('MM-DD-YYYY hh:mm A')}}</span>

            <span slot="invnum" slot-scope="props">
              <router-link v-on:click.native="setInvoice(props.row)" v-if="props.row.inv_passed === 2 || props.row.inv_passed === 5 || props.row.inv_passed === 14 || props.row.inv_passed === 24 || props.row.inv_passed === 34 || props.row.inv_passed === 44 || props.row.inv_passed === 54" :to="{ name: 'Accountant Vendor Edit Invoice', path: '/accountant/invoices/vendor/edit', params: { id: props.row.invoiceid, invoice: props.row, set: 0, rejected: 0, fake_vendor: props.row.vid }}">{{props.row.invnum}} <i class="fa fa-edit"></i></router-link>
              <router-link v-else-if="props.row.inv_passed === 8" v-on:click.native="setInvoice(props.row, 1)" :to="{ name: 'Accountant PM Invoice', path: '/accountant/invoice', params: { invoice: props.row, set: 1 }}">{{props.row.invnum}}</router-link>
              <router-link v-on:click.native="setInvoice(props.row)" v-else :to="{ name: 'Accountant PM Invoice', path: '/accountant/invoice', params: { invoice: props.row, set: 0 }}">{{props.row.invnum}}</router-link>
              <p class="mb-0 d-sm-block d-md-none">{{props.row.creation_date | moment('MM-DD-YYYY')}}</p>
            </span>

            <div slot="literalStatus" slot-scope="props">
              <div v-if="props.row.accountant_paid === 0">
                <div v-if="props.row.inv_passed === 9">
                  <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By WSS (Submit Again)</button>
                </div>

                <div v-if="props.row.inv_passed === 8 && props.row.acc_project != 1">
                  <button class="btn-sm btn-warning btn-pm"><i class="fa fa-question"></i> Pending By Project Manager</button>
                </div>
                <div v-if="props.row.inv_passed === 8 && props.row.acc_project == 1">
                  <button class="btn-sm btn-warning btn-pm" style="background: #24cabf !important;"><i class="fa fa-question"></i> Pending For First Approval</button>
                </div>

                <div v-if="props.row.inv_passed === 7 ">
                  <button class="btn-sm btn-success"><i class="fa fa-clock"></i> Ready For Payment</button>
                </div>

                <div v-if="props.row.inv_passed === 6 ">
                  <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By Higher Management</button>
                </div>

                <div v-if="props.row.inv_passed === 5 ">
                  <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Project Manager (Pending Vendor)</button>
                </div>

                <div v-if="props.row.inv_passed === 0 ">
                  <button class="btn-sm btn-warning"><i class="fa fa-question"></i> Pending By WSS</button>
                </div>

                <div v-if="props.row.inv_passed === 2 ">
                  <router-link class="w-100 text-center d-block btn-sm btn-blue"  :to="{ name: 'Vendor Edit Invoice', path: '/vendor/invoices/edit', params: { id: props.row.invoiceid, invoice: props.row }}"><i class="fa fa-save"></i> Draft</router-link>
                </div>

                <div v-if="props.row.inv_passed === 11">
                 <button class="btn-sm btn-success"><i class="fa fa-clock"></i> Ready For Payment</button>
                </div>

                <div v-if="props.row.inv_passed === 13">
                 <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By Higher Management</button>
               </div>

                <div v-if="props.row.inv_passed === 14">
                 <router-link class="w-100 text-center d-block btn-sm btn-danger" :to="{ name: 'Vendor Edit Invoice', path: '/vendor/invoices/edit', params: { id: props.row.invoiceid, invoice: props.row }}"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</router-link>
                </div>

                <div v-if="props.row.inv_passed === 21">
                 <button class="btn-sm btn-success"><i class="fa fa-clock"></i> Ready For Payment</button>
                </div>

                <div v-if="props.row.inv_passed === 23">
                 <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By Higher Management</button>
                </div>

                <div v-if="props.row.inv_passed === 24">
                 <router-link class="w-100 text-center d-block btn-sm btn-danger" :to="{ name: 'Vendor Edit Invoice', path: '/vendor/invoices/edit', params: { id: props.row.invoiceid, invoice: props.row }}"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</router-link>
                </div>

                <div v-if="props.row.inv_passed === 31">
                 <button class="btn-sm btn-success"><i class="fa fa-clock"></i> Ready For Payment</button>
                </div>

                <div v-if="props.row.inv_passed === 33">
                 <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By Higher Management</button>
                </div>

                <div v-if="props.row.inv_passed === 34">
                 <router-link class="w-100 text-center d-block btn-sm btn-danger" :to="{ name: 'Vendor Edit Invoice', path: '/vendor/invoices/edit', params: { id: props.row.invoiceid, invoice: props.row }}"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</router-link>
                </div>

                <div v-if="props.row.inv_passed === 41">
                 <button class="btn-sm btn-success"><i class="fa fa-clock"></i> Ready For Payment</button>
                </div>

                <div v-if="props.row.inv_passed === 43">
                 <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By Higher Management</button>
                </div>

                <div v-if="props.row.inv_passed === 44">
                 <router-link class="w-100 text-center d-block btn-sm btn-danger" :to="{ name: 'Vendor Edit Invoice', path: '/vendor/invoices/edit', params: { id: props.row.invoiceid, invoice: props.row }}"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</router-link>
                </div>

                <div v-if="props.row.inv_passed === 51">
                 <button class="btn-sm btn-success"><i class="fa fa-clock"></i> Ready For Payment</button>
                </div>

                <div v-if="props.row.inv_passed === 54">
                 <router-link class="w-100 text-center d-block btn-sm btn-danger"  :to="{ name: 'Vendor Edit Invoice', path: '/vendor/invoices/edit', params: { id: props.row.invoiceid, invoice: props.row }}"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</router-link>
                </div>
              </div>
              <div v-if="props.row.accountant_paid === 1">
                <button class="btn-sm btn-success"><i class="fa fa-check"></i> Paid</button>
              </div>
            </div>
            <div slot="history" slot-scope="props">
              <button v-b-modal.history @click="sendHistory(props.row)" class="btn-sm white btn-secondary"><i class="fa fa-history"></i></button>
            </div>

          </v-client-table>
        </b-card>
        <b-modal ok-only id="history" ref="history" :title="'Invoice #' + history.invnum" title-tag="h2" centered size="xl">
          <InvoiceHistory v-bind:history="history"></InvoiceHistory>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
import {ClientTable} from 'vue-tables-2'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';
import InvoiceHistory from './../invoice/InvoiceHistory'

Vue.use(ClientTable)

export default {
  name: 'Vendor',
  components: {
    ClientTable,
    InvoiceHistory,
  },
  data () {
    return {
      columns: ['literalStatus', 'invnum', 'ticketnum', 'vendor_invoice_number', 'creation_date', 'total', 'quickpay_total', 'history'],
      invoiceData: [],
      companyData: [],
      history: [],
      type: {
        type: 'Unprocessed',
      },
      typeOptions: [
        {
          type: 'Processed',
        },
        {
          type: 'Unprocessed',
        }
      ],
      listOfVendors : [{
        company_name: 'All Vendors',
        vid: 0,
      }],
      vendorSelection : [],
      project: [],
      projectid: null,
      companyid: '',
      projectData: [],
      companyData: [],
      type: {
        type: 'All',
      },
      typeOptions: [
        {
          type: 'All',
        },
        {
          type: 'Rejected (Pending Vendor)',
        },
        {
          type: 'Pending For PM or First Approval (Higher MGMT)',
        },
        {
          type: 'Ready For Payment',
        },
        {
          type: 'Paid',
        },
        {
          type: 'Draft',
        },
        {
          type: 'Pending WSS',
        },
      ],
      options: {
        filterByColumn: true,
        headings: {
          literalStatus: 'Status',
          invnum: 'Invoice #',
          creation_date: 'Submitted Date',
          total: 'Amount',
          quickpay_total: 'Quickpay',
          description: 'Description',
          well: 'Well License #',
          wss: 'WSS',
          wts: 'WTS',
          ticketnum: 'Field Ticket #',
          vendor_invoice_number: 'Vendor Invoice #'
        },
        sortable: ['quickpay_total', 'literalStatus', 'invnum', 'creation_date', 'total', 'description', 'well', 'wss', 'wts', ],
        filterable: ['literalStatus', 'invnum', 'ticketnum', 'vendor_invoice_number', 'creation_date', 'total', 'description', 'well', 'wss', 'wts', 'quickpay_total'],
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        pagination: {
          chunk: 5,
          edge: false,
          nav: 'scroll'
        }
      },
      theme: 'bootstrap4',
      template: 'default',
      useVuex: false
    }
  },
  watch: {
    listOfVendors(val){
      if(val.length > 1){
        //this.getProjects();
      }
    }
  },
  async mounted() {
    console.log(this.$session.get('vendorSelection'))

    this.vendorSelection = this.$session.get('vendorSelection')

    await this.$http.get('/manager/get/profile')
    .then(response => {
      this.companyData = response.data;
      this.getListOfVendors();
      // this.getProjects();

      // if(this.$session.has('project')){
      //   this.projectid = this.$session.get('project')
      // }
      // if(this.$session.has('vendorSelection')){


      // }
    })

    /* if(this.$session.get('company') && this.$session.get('project')){
      this.companyid = this.$session.get('company');
      this.projectid = this.$session.get('project');
      this.loadInvoices();
    } */
  },
  methods: {
    getListOfVendors(){
      this.$axios.get('/wts/get/projects')
      .then(response => {
        if (response.status === 200) {
          var projects = response.data.result;
          for (var i = 0; i < projects.length; i++) {
            this.$axios.get('/wts/get/vas/' + projects[i].projectid)
            .then(response => {
              this.listOfVendors = this.lodash.uniqBy(this.listOfVendors.concat(response.data.result), 'vid');
            })
          }
          this.loading = false;
          if(this.$session.has('vendorSelection')){
             console.log('set selection')
             this.vendorSelection = this.$session.get('vendorSelection')
             //this.getProjects();
          }
        }
      })
    },
    clearData(){
      this.invoiceData = [];
    },
    async loadInvoices() {
        console.log('run load invoices');
      this.invoiceData = [];
      const clear = await this.clearData();
      //this.$session.set('company', this.companyid)
      //this.$session.set('project', this.projectid)

      if(this.projectid.projectname == 'All'){
        for (var x = 0; x < this.projectData.length; x++) {
          if(this.projectData[x].projectname != 'All'){
            if(this.vendorSelection.vid == 0){
              for (var i = 0; i < this.listOfVendors.length; i++) {
                if(this.listOfVendors[i].vid > 0){
                  this.$http.get('/vendor/get/invoices/' + this.companyData.companyid + "/" + this.projectData[x].projectid + '/' + this.listOfVendors[i].vid)
                  .then(response => {
                    var temp = response.data.result;

                    this.invoiceData = this.invoiceData.concat(temp.sort((n2,n1) => n1.creation_date - n2.creation_date).reverse());


                    if(this.type.type == 'Rejected (Pending Vendor)') {
                      this.invoiceData = this.invoiceData.filter(x => x.inv_passed == 5 || x.inv_passed == 9 || x.inv_passed == 14 || x.inv_passed == 24 || x.inv_passed == 34 || x.inv_passed == 44 || x.inv_passed == 54);
                    }
                    if(this.type.type == 'Pending For PM or First Approval (Higher MGMT)') {
                      this.invoiceData = this.invoiceData.filter(x => x.inv_passed == 6 || x.inv_passed == 8 || x.inv_passed == 13 || x.inv_passed == 23 || x.inv_passed == 33 || x.inv_passed == 43 || x.inv_passed == 53);
                    }
                    if(this.type.type == 'Ready For Payment') {
                      this.invoiceData = this.invoiceData.filter(x => x.inv_passed == 7 || x.inv_passed == 11 || x.inv_passed == 21 || x.inv_passed == 31 || x.inv_passed == 41 || x.inv_passed == 51);
                    }
                    if(this.type.type == 'Paid') {
                      this.invoiceData = this.invoiceData.filter(x=>x.accountant_paid == 1);
                    }
                    if(this.type.type == 'Draft') {
                      this.invoiceData = this.invoiceData.filter(x=>x.inv_passed == 2);
                    }
                    if(this.type.type == 'Pending WSS') {
                      this.invoiceData = this.invoiceData.filter(x=>x.inv_passed == 0);
                    }
              
              this.invoiceData.map((x,i)=>{

that.$http.get('/accountant/qbyte/invoices/inovoiceData/' + x.invoiceid).then(res=>{

  if(res.data.total !== undefined){

 that.invoiceData[i].total = res.data.total;
  that.invoiceData[i].quickpay_total= res.data.quickpay_total;
  
  }

 }).catch(err=>{

  console.log(err);

})

});
                  })
                }
              }
            }
            else {
              this.$http.get('/vendor/get/invoices/' + this.companyData.companyid + "/" + this.projectData[x].projectid + '/' + this.vendorSelection.vid)
              .then(response => {
                var temp = response.data.result;
                for (var i = 0; i < temp.length; i++) {
                  if(temp[i].accountant_paid === 0){
                    if(temp[i].inv_passed == 9){
                      temp[i].literalStatus = "Rejected By WSS (Submit Again)";
                    }

                    if(temp[i].inv_passed == 8 ){
                      temp[i].literalStatus = "Pending By Project Manager";
                    }

                    if(temp[i].inv_passed == 7 ){
                      temp[i].literalStatus = "Ready For Payment";
                    }

                    if(temp[i].inv_passed == 6 ){
                      temp[i].literalStatus = "Pending By Higher Management";
                    }

                    if(temp[i].inv_passed == 5 ){
                      temp[i].literalStatus = "Pending By Project Manager";
                    }

                    if(temp[i].inv_passed == 0 ){
                      temp[i].literalStatus = "Pending By WSS";
                    }

                    if(temp[i].inv_passed == 2 ){
                      temp[i].literalStatus = "Draft";
                    }

                    if(temp[i].inv_passed == 11){
                      temp[i].literalStatus = "Ready For Payment";
                    }

                    if(temp[i].inv_passed == 13){
                      temp[i].literalStatus = "Pending By Higher Management";
                    }

                    if(temp[i].inv_passed == 14){
                      temp[i].literalStatus = "Rejected By Management (Pending Vendor)";
                    }

                    if(temp[i].inv_passed == 21){
                      temp[i].literalStatus = "Ready For Payment";
                    }

                    if(temp[i].inv_passed == 23){
                      temp[i].literalStatus = "Pending By Higher Management";
                    }

                    if(temp[i].inv_passed == 24){
                      temp[i].literalStatus = "Rejected By Management (Pending Vendor)";
                    }

                    if(temp[i].inv_passed == 31){
                      temp[i].literalStatus = "Ready For Payment";
                    }

                    if(temp[i].inv_passed == 33){
                      temp[i].literalStatus = "Pending By Higher Management";
                    }

                    if(temp[i].inv_passed == 34){
                      temp[i].literalStatus = "Rejected By Management (Pending Vendor)";
                    }

                    if(temp[i].inv_passed == 41){
                      temp[i].literalStatus = "Ready For Payment";
                    }

                    if(temp[i].inv_passed == 43){
                      temp[i].literalStatus = "Pending By Higher Management";
                    }

                    if(temp[i].inv_passed == 44){
                      temp[i].literalStatus = "Rejected By Management (Pending Vendor)";
                    }

                    if(temp[i].inv_passed == 51){
                      temp[i].literalStatus = "Ready For Payment";
                    }

                    if(temp[i].inv_passed == 54){
                      temp[i].literalStatus = "Rejected By Management (Pending Vendor)";
                    }
                  }
                  if(temp[i].accountant_paid === 1){
                    temp[i].literalStatus = "Paid";
                  }
                }
                this.invoiceData = this.invoiceData.concat(temp.sort((n2,n1) => n1.creation_date - n2.creation_date).reverse());
                if(this.type.type == 'Rejected (Pending Vendor)') {
                  this.invoiceData = this.invoiceData.filter(x => x.inv_passed == 5 || x.inv_passed == 9 || x.inv_passed == 14 || x.inv_passed == 24 || x.inv_passed == 34 || x.inv_passed == 44 || x.inv_passed == 54);
                }
                if(this.type.type == 'Pending For PM or First Approval (Higher MGMT)') {
                  this.invoiceData = this.invoiceData.filter(x => x.inv_passed == 6 || x.inv_passed == 8 || x.inv_passed == 13 || x.inv_passed == 23 || x.inv_passed == 33 || x.inv_passed == 43 || x.inv_passed == 53);
                }
                if(this.type.type == 'Ready For Payment') {
                  this.invoiceData = this.invoiceData.filter(x => x.inv_passed == 7 || x.inv_passed == 11 || x.inv_passed == 21 || x.inv_passed == 31 || x.inv_passed == 41 || x.inv_passed == 51);
                }
                if(this.type.type == 'Paid') {
                  this.invoiceData = this.invoiceData.filter(x=>x.accountant_paid == 1);
                }
                if(this.type.type == 'Draft') {
                  this.invoiceData = this.invoiceData.filter(x=>x.inv_passed == 2);
                }
                if(this.type.type == 'Pending WSS') {
                  this.invoiceData = this.invoiceData.filter(x=>x.inv_passed == 0);
                }

                this.invoiceData.map((x,i)=>{

that.$http.get('/accountant/qbyte/invoices/inovoiceData/' + x.invoiceid).then(res=>{

  if(res.data.total !== undefined){

 that.invoiceData[i].total = res.data.total;
  that.invoiceData[i].quickpay_total= res.data.quickpay_total;
  
  }

 }).catch(err=>{

  console.log(err);

})

});
              })
            }
          }
        }
      }
      else if(this.vendorSelection.vid == 0){
        for (var i = 0; i < this.listOfVendors.length; i++) {
          console.log(this.listOfVendors[i].vid)
          if(this.listOfVendors[i].vid > 0){
            this.$http.get('/vendor/get/invoices/' + this.companyData.companyid + "/" + this.projectid.projectid + '/' + this.listOfVendors[i].vid)
            .then(response => {
              var temp = response.data.result;

              this.invoiceData = this.invoiceData.concat(temp.sort((n2,n1) => n1.creation_date - n2.creation_date).reverse());

              if(this.type.type == 'Rejected (Pending Vendor)') {
                this.invoiceData = this.invoiceData.filter(x => x.inv_passed == 5 || x.inv_passed == 9 || x.inv_passed == 14 || x.inv_passed == 24 || x.inv_passed == 34 || x.inv_passed == 44 || x.inv_passed == 54);
              }
              if(this.type.type == 'Pending For PM or First Approval (Higher MGMT)') {
                this.invoiceData = this.invoiceData.filter(x => x.inv_passed == 6 || x.inv_passed == 8 || x.inv_passed == 13 || x.inv_passed == 23 || x.inv_passed == 33 || x.inv_passed == 43 || x.inv_passed == 53);
              }
              if(this.type.type == 'Ready For Payment') {
                this.invoiceData = this.invoiceData.filter(x => x.inv_passed == 7 || x.inv_passed == 11 || x.inv_passed == 21 || x.inv_passed == 31 || x.inv_passed == 41 || x.inv_passed == 51);
              }
              if(this.type.type == 'Paid') {
                this.invoiceData = this.invoiceData.filter(x=>x.accountant_paid == 1);
              }
              if(this.type.type == 'Draft') {
                this.invoiceData = this.invoiceData.filter(x=>x.inv_passed == 2);
              }
              if(this.type.type == 'Pending WSS') {
                this.invoiceData = this.invoiceData.filter(x=>x.inv_passed == 0);
              }

              this.invoiceData.map((x,i)=>{

that.$http.get('/accountant/qbyte/invoices/inovoiceData/' + x.invoiceid).then(res=>{

  if(res.data.total !== undefined){

 that.invoiceData[i].total = res.data.total;
  that.invoiceData[i].quickpay_total= res.data.quickpay_total;
  
  }

 }).catch(err=>{

  console.log(err);

})

});
              // if(this.vendorSelection.company_name != "All Vendors"){
              //   console.log('yes')
              //   this.invoiceData.filter(x=>x.vid = this.vendorSelection.vid)
              // }
            })
          }
        }
      }
      else {
        this.$http.get('/vendor/get/invoices/' + this.companyData.companyid + "/" + this.projectid.projectid + '/' + this.vendorSelection.vid)
        .then(response => {
          var temp = response.data.result;
          for (var i = 0; i < temp.length; i++) {
            if(temp[i].accountant_paid === 0){
              if(temp[i].inv_passed == 9){
                temp[i].literalStatus = "Rejected By WSS (Submit Again)";
              }

              if(temp[i].inv_passed == 8 ){
                temp[i].literalStatus = "Pending By Project Manager";
              }

              if(temp[i].inv_passed == 7 ){
                temp[i].literalStatus = "Ready For Payment";
              }

              if(temp[i].inv_passed == 6 ){
                temp[i].literalStatus = "Pending By Higher Management";
              }

              if(temp[i].inv_passed == 5 ){
                temp[i].literalStatus = "Pending By Project Manager";
              }

              if(temp[i].inv_passed == 0 ){
                temp[i].literalStatus = "Pending By WSS";
              }

              if(temp[i].inv_passed == 2 ){
                temp[i].literalStatus = "Draft";
              }

              if(temp[i].inv_passed == 11){
                temp[i].literalStatus = "Ready For Payment";
              }

              if(temp[i].inv_passed == 13){
                temp[i].literalStatus = "Pending By Higher Management";
              }

              if(temp[i].inv_passed == 14){
                temp[i].literalStatus = "Rejected By Management (Pending Vendor)";
              }

              if(temp[i].inv_passed == 21){
                temp[i].literalStatus = "Ready For Payment";
              }

              if(temp[i].inv_passed == 23){
                temp[i].literalStatus = "Pending By Higher Management";
              }

              if(temp[i].inv_passed == 24){
                temp[i].literalStatus = "Rejected By Management (Pending Vendor)";
              }

              if(temp[i].inv_passed == 31){
                temp[i].literalStatus = "Ready For Payment";
              }

              if(temp[i].inv_passed == 33){
                temp[i].literalStatus = "Pending By Higher Management";
              }

              if(temp[i].inv_passed == 34){
                temp[i].literalStatus = "Rejected By Management (Pending Vendor)";
              }

              if(temp[i].inv_passed == 41){
                temp[i].literalStatus = "Ready For Payment";
              }

              if(temp[i].inv_passed == 43){
                temp[i].literalStatus = "Pending By Higher Management";
              }

              if(temp[i].inv_passed == 44){
                temp[i].literalStatus = "Rejected By Management (Pending Vendor)";
              }

              if(temp[i].inv_passed == 51){
                temp[i].literalStatus = "Ready For Payment";
              }

              if(temp[i].inv_passed == 54){
                temp[i].literalStatus = "Rejected By Management (Pending Vendor)";
              }
            }
            if(temp[i].accountant_paid === 1){
              temp[i].literalStatus = "Paid";
            }
          }
          this.invoiceData = this.invoiceData.concat(temp.sort((n2,n1) => n1.creation_date - n2.creation_date).reverse());

          if(this.type.type == 'Rejected (Pending Vendor)') {
            this.invoiceData = this.invoiceData.filter(x => x.inv_passed == 5 || x.inv_passed == 9 || x.inv_passed == 14 || x.inv_passed == 24 || x.inv_passed == 34 || x.inv_passed == 44 || x.inv_passed == 54);
          }
          if(this.type.type == 'Pending For PM or First Approval (Higher MGMT)') {
            this.invoiceData = this.invoiceData.filter(x => x.inv_passed == 6 || x.inv_passed == 8 || x.inv_passed == 13 || x.inv_passed == 23 || x.inv_passed == 33 || x.inv_passed == 43 || x.inv_passed == 53);
          }
          if(this.type.type == 'Ready For Payment') {
            this.invoiceData = this.invoiceData.filter(x => x.inv_passed == 7 || x.inv_passed == 11 || x.inv_passed == 21 || x.inv_passed == 31 || x.inv_passed == 41 || x.inv_passed == 51);
          }
          if(this.type.type == 'Paid') {
            this.invoiceData = this.invoiceData.filter(x=>x.accountant_paid == 1);
          }
          if(this.type.type == 'Draft') {
            this.invoiceData = this.invoiceData.filter(x=>x.inv_passed == 2);
          }
          if(this.type.type == 'Pending WSS') {
            this.invoiceData = this.invoiceData.filter(x=>x.inv_passed == 0);
          }
          this.invoiceData.map((x,i)=>{

that.$http.get('/accountant/qbyte/invoices/inovoiceData/' + x.invoiceid).then(res=>{

  if(res.data.total !== undefined){

 that.invoiceData[i].total = res.data.total;
  that.invoiceData[i].quickpay_total= res.data.quickpay_total;
  
  }

 }).catch(err=>{

  console.log(err);

})

});
        })
      }
    },
    getProjects(){

      //this.$session.set('vendorSelection', this.vendorSelection);
      //this.projectid = this.$session.get('project')

      this.$axios.get('/wts/get/projects')
      .then(response => {
        this.projectData = response.data.result;
        this.projectData.unshift({
          "projectname": "All"
        })
        this.projectid = this.projectData[0];
        //if(this.$session.has('project')){

          //this.loadInvoices();
        //}
      })
    },
    sendHistory(history) {
      this.history = history;
    },
  },
}
</script>

<style lang="scss">
.vendor-table {
  overflow-x: hidden !important;
}
@media screen and (max-width: 767px){
  .vendor-table {
    th:nth-child(4),
    td:nth-child(4),
    th:nth-child(5),
    td:nth-child(5),
    th:nth-child(6),
    td:nth-child(6),
    th:nth-child(7),
    td:nth-child(7),
    th:nth-child(3),
    td:nth-child(3){
      display: none !important;
    }
    td, th{
      width: 50%;
    }
  }
}
@media screen and (max-width: 580px){
  .vendor-table {
    td, th{
      width: 50%;
    }
  }
}
</style>
